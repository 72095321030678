import { WINDOW_KEY } from './constants';
import toast from './toast';
import broadcast from './broadcast';

export default function init() {
    window[WINDOW_KEY] = window[WINDOW_KEY] || [];
    const list = window[WINDOW_KEY];

    if (window[WINDOW_KEY].__fake) {
        throw new Error(
            'common-browserbundle is already loaded, please include the script only once.',
        );
    }

    function handler(data) {
        const { name, ...params } = data;

        switch (name) {
            case 'toast':
                toast(params);
                break;
            case 'trackjs':
                if (window.console && window.console.warn) {
                    window.console.warn(
                        `commonQueue: trackjs is removed from common-browserbundle. Check track-js-loader docs.`,
                    );
                }
                break;
            case 'broadcast':
                broadcast(params);
                break;
            default:
                if (window.console && window.console.warn) {
                    window.console.warn(
                        `commonQueue: ${name} is not a registered handler`,
                    );
                }
        }
    }

    window[WINDOW_KEY] = {
        __fake: true,
        push(data) {
            handler(data);
        },
    };

    list.forEach(entry => handler(entry));
}
