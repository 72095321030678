
import el from '@finn-no/create-element';

function createWrapper() {
    const wrapper = el('div', { 'data-toast-wrapper': '' });
    const nav = document.querySelector('nav');
    const top = nav ? `${nav.offsetHeight}px` : 0;
    Object.assign(wrapper.style, {
        position: 'fixed',
        zIndex: 3,
        top,
        left: 0,
        right: 0,
    });
    return wrapper;
}

function createToast({ level, node }) {
    const toastEl = el(`div.toast.toast--${level}`);
    const containerEl = el('div.container');
    const pageEl = el('div.page');
    const modEl = el('div.mod');
    const innerEl = el('div.inner');
    const bdEl = el('div.bd');
    bdEl.appendChild(node);
    innerEl.appendChild(bdEl);
    modEl.appendChild(innerEl);
    pageEl.appendChild(modEl);
    containerEl.appendChild(pageEl);
    toastEl.appendChild(containerEl);
    return toastEl;
}

function toastWrapperElem() {
    return document.querySelector('[data-toast-wrapper]');
}

function appendToast({ level, node, timeout }) {
    const toast = createToast({ level, node });
    const wrapper = toastWrapperElem();

    wrapper.appendChild(toast);

    setTimeout(() => {
        wrapper.removeChild(toast);
    }, timeout);
}

export default ({
    level = 'success',
    message = '',
    node,
    timeout = 4000,
} = {}) => {
    const { body } = document;
    if (!body) {
        return;
    }

    if (!toastWrapperElem()) {
        body.appendChild(createWrapper());
    }

    let nodee = node;
    if (!nodee) {
        nodee = el('p.u-mb0', { role: 'status' });
        nodee.innerHTML = message;
    }

    appendToast({ level, node: nodee, timeout });
};
