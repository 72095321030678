import el from '@finn-no/create-element';

function generateBroadcastHtml(heading, message, index, dismissible = false) {
    // Done this way to allow html (links in particular), in the message
    const innerMessage = document.createElement('p');
    innerMessage.innerHTML = message;

    let dismissButtonElement = null;

    if (dismissible) {
        dismissButtonElement = el(
            'button.button--pill.button--small.button--has-icon.broadcast__dismiss',
            {
                type: 'button',
                'aria-label': 'Lukk',
            },
            el(
                'span.icon.icon--cross',
                null,
                null,
            ),
        );
    }

    const broadcastElement = el(
        'section.broadcast',
        {
            'aria-label': `Viktig melding${index ? ` ${index + 1}` : ''}`,
        },
        el(
            'div.broadcast__content',
            null,
            heading ? el('h2.u-t3', null, heading) : null,
            innerMessage,
        ),
        dismissButtonElement,
    );

    if (dismissible && dismissButtonElement != null) {
        const listener = () => {
            broadcastElement.remove();
            dismissButtonElement.removeEventListener('click', listener);
        };

        dismissButtonElement.addEventListener('click', listener);
    }

    return broadcastElement;
}

export default function broadcast({
    ele = document.getElementById('broadcast'),
    broadcastUrl = 'https://www.finn.no/broadcasts',
    dismissible = false,
} = {}) {
    if (!ele) {
        window.console.error(
            new Error(
                'An element with id=broadcast should be included for messages',
            ),
        );
        return null;
    }

    const url = new URL(broadcastUrl);

    url.searchParams.set('path', window.location.href);

    return window
        .fetch(url.href, { credentials: 'include' }) // Needs credentials to avoid new USERID
        .then(response => {
            if (response.ok) {
                return response.json();
            }

            return Promise.reject(response);
        })
        .then(broadcastMessages => {
            broadcastMessages
                .filter(broadcastMessage => broadcastMessage.message)
                .map((broadcastMessage, index) =>
                    generateBroadcastHtml(
                        broadcastMessage.heading,
                        broadcastMessage.message,
                        index,
                        dismissible,
                    ),
                )
                .forEach(broadcastMessage => {
                    ele.appendChild(broadcastMessage);
                });
        });
}
